import {
  TextInput,
  PasswordInput,
  Checkbox,
  Anchor,
  Paper,
  Title,
  Text,
  Container,
  Group,
  Button,
} from "@mantine/core";
import classes from "./AuthenticationTitle.module.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

export function Login() {
  const navigate = useNavigate();

  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [loading, setLoading] = useState(false);

  function handleLogin(e) {
    e.preventDefault();
    if (login.length < 3) {
      return Swal.fire("Informe o usuário !", "", "error");
    }
    if (senha.length < 3) {
      return Swal.fire("Informe a senha !", "", "error");
    }
    setLoading(true);
    axios({
      method: "post",
      url: "https://rifa-prod.onrender.com/api/User/Login",
      data: {
        usuario: login,
        senha: senha,
      },
    })
      .then((res) => {
        window.localStorage.setItem("x-access-token", res.data.token);

        navigate("/admin", { replace: true });
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        if (err.response) return Swal.fire(err.response.data, "", "error");
        return Swal.fire("Erro interno no servidor !", "", "error");
      });
  }
  return (
    <Container size={420} my={40}>
      <Title ta="center" className={classes.title}>
        Bem vindo de volta!
      </Title>
      <Text c="dimmed" size="sm" ta="center" mt={5}>
        Não tem uma conta ainda?{" "}
        <Anchor size="sm" component="button">
          Criar uma conta{" "}
        </Anchor>
      </Text>

      <Paper withBorder shadow="md" p={30} mt={30} radius="md">
        <TextInput
          label="Usuario"
          placeholder="Informe seu nome de usuário"
          required
          onChange={(e) => setLogin(e.target.value)}
          value={login}
        />
        <PasswordInput
          label="Senha"
          placeholder="Sua senha"
          required
          mt="md"
          onChange={(e) => setSenha(e.target.value)}
          value={senha}
        />
        <Group justify="space-between" mt="lg">
          <Checkbox label="Remember me" />
          <Anchor component="button" size="sm">
            Esqueceu sua senha?
          </Anchor>
        </Group>

        {loading ? (
          <Button loading fullWidth mt="xl" onClick={handleLogin}>
            Entrar
          </Button>
        ) : (
          <Button fullWidth mt="xl" onClick={handleLogin}>
            Entrar
          </Button>
        )}
      </Paper>
    </Container>
  );
}
