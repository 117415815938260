import Footer from "../../components/Footer";
import NavBarAdmin from "../../components/navBarAdmin/NavBarAdmin";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { Loader } from "@mantine/core";

function BilhetesRifa() {
  const navigate = useNavigate();
  const params = useParams();

  const token = window.localStorage.getItem("x-access-token");

  const [bilhetes, setBilhetes] = useState([]);
  const [loading, setLoading] = useState(false);

  function BuscarBilhetes() {
    setLoading(true);
    axios({
      method: "get",
      url: `https://rifa-prod.onrender.com/api/Bilhete/ByRifaId/${params.id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        console.log("🚀 ~ .then ~ res.data:", res.data);
        setLoading(false);
        setBilhetes(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log("🚀 ~ Cadastrar ~ err:", err);
        Swal.fire(
          `Tivemos um problema ao buscar as rifas, por favor entre em contato com um administrador !`,
          "",
          "error"
        );
      });
  }

  useEffect(() => {
    BuscarBilhetes();
  }, []);
  return (
    <div>
      <NavBarAdmin />
      <div className="container mt-4 col-xs-6 col-sm-6 col-md-6 col-lg-7">
        <div className="mt-2 d-flex justify-content-center">
          {loading ? <Loader color="green" /> : <div></div>}
        </div>

        {bilhetes ? (
          <div className="row mt-2 d-flex justify-content-center">
            {bilhetes.map((item) => (
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-4 mt-2">
                {item.premiado ? (
                  <div
                    className="card border border-success"
                    style={{ width: "18rem" }}
                  >
                    <div className="card-body">
                      <p className="card-text">
                        <span className="fw-bold">Email:</span>{" "}
                        {item.emailComprador}
                      </p>
                      <p className="card-text text-success">
                        <span className="fw-bold">Número: </span> {item.numero}
                      </p>
                      <p className="card-text">
                        <span className="fw-bold">ID Pagamento: </span>{" "}
                        {item.pagamentoMPId}
                      </p>
                    </div>
                  </div>
                ) : (
                  <div className="card border" style={{ width: "18rem" }}>
                    <div className="card-body">
                      <p className="card-text">
                        <span className="fw-bold">Email:</span>{" "}
                        {item.emailComprador}
                      </p>
                      <p className="card-text">
                        <span className="fw-bold">Número: </span> {item.numero}
                      </p>
                      <p className="card-text">
                        <span className="fw-bold">ID Pagamento: </span>{" "}
                        {item.pagamentoMPId}
                      </p>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </div>
        ) : (
          <div></div>
        )}
      </div>
      <Footer />
    </div>
  );
}

export default BilhetesRifa;
