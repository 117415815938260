import { Link } from "react-router-dom";

function Footer() {
  return (
    <div>
      <div class="container">
        <footer class="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
          <div class="col-md-4 d-flex align-items-center">
            <span class="mb-3 mb-md-0 text-muted">© 2024 RifaAi, Inc</span>
            <span class="mb-3 mb-md-0 text-muted mx-2">
              rifaai.contact@gmail.com
            </span>
            <Link to="/admin" className="mb-3 mb-md-0 text-muted mx-2">
              Login
            </Link>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default Footer;
