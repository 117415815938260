import { Route, Routes } from "react-router-dom";
import Inicio from "../pages/Inicio";
import { FaqWithImage } from "../pages/faq/Faq";
import Rifa from "../pages/Rifa";
import Termos from "../pages/Termos";
import Bilhetes from "../pages/Bilhetes";
import { Login } from "../pages/admin/login/Login";
import Admin from "../pages/admin/Admin";
import CriarRifa from "../pages/admin/CriarRifa";
import EditarRifa from "../pages/admin/EditarRifa";
import Private from "../pages/admin/Private/Private";
import BilhetesRifa from "../pages/admin/BilhetesRifa";

function RoutesApp() {
  return (
    <Routes>
      <Route path="/" element={<Inicio />} />
      <Route path="/faq" element={<FaqWithImage />} />
      <Route path="/termos" element={<Termos />} />
      <Route path="/rifa/:id" element={<Rifa />} />
      <Route path="/bilhetes" element={<Bilhetes />} />

      <Route path="/login" element={<Login />} />
      <Route
        path="/admin"
        element={
          <Private>
            <Admin />
          </Private>
        }
      />
      <Route
        path="/criar"
        element={
          <Private>
            <CriarRifa />{" "}
          </Private>
        }
      />
      <Route
        path="/editar/:id"
        element={
          <Private>
            <EditarRifa />{" "}
          </Private>
        }
      />
      <Route
        path="/bilhetesrifa/:id"
        element={
          <Private>
            <BilhetesRifa />{" "}
          </Private>
        }
      />
    </Routes>
  );
}

export default RoutesApp;
