import NavBar from "../components/NavBar";

function Termos() {
  return (
    <div>
      <NavBar />
      <div
        className="container border rounded-4 mt-4 "
        style={{ backgroundColor: "white" }}
      >
        <div className="row p-2">
          <div className="col">
            <h3>Política de privacidade</h3>
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            <h5>1. Termos</h5>
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.1. O RifaAi está comprometida com a confidencialidade e proteção
            dos dados pessoais de seus Usuários e transparência em seus valores
            em relação a seus Usuários, colaboradores, fornecedores e parceiros.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.2. Esta Política de Privacidade (“Política de Privacidade”)
            aplica-se apenas aos dados pessoais dos Usuários processados pelo
            RifaAi como parte da prestação dos serviços dos Serviços RifaAi por
            meio da Plataforma RifaAi.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.3 Esta Política de Privacidade não abrange as práticas de
            privacidade e proteção de dados de terceiros. Para políticas
            aplicáveis aos seus dados pessoais processados por terceiros
            parceiros dos Serviços RifaAi sugerimos que consulte os respetivos
            sites.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.4. Coleta de Dados: O RifaAi coleta dados pessoais dos usuários,
            incluindo informações como nome, endereço de e-mail, e dados de
            pagamento, conforme necessário para a prestação dos serviços
            oferecidos pela plataforma.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.5. Uso dos Dados: Os dados coletados são utilizados para fornecer
            e melhorar os serviços do RifaAi, personalizar a experiência do
            usuário, processar transações e fornecer suporte ao cliente.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.6. Compartilhamento de Dados: O RifaAi pode compartilhar dados
            pessoais com terceiros apenas quando necessário para a prestação dos
            serviços ou conforme exigido por lei.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.7. Segurança dos Dados: O RifaAi implementa medidas de segurança
            adequadas para proteger os dados pessoais dos usuários contra acesso
            não autorizado, alteração, divulgação ou destruição.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.8. Retenção de Dados: O RifaAi retém os dados pessoais dos
            usuários apenas pelo tempo necessário para cumprir com as
            finalidades para as quais foram coletados ou conforme exigido por
            lei.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.9. Direitos dos Usuários: Os usuários têm o direito de acessar,
            corrigir, atualizar ou excluir seus dados pessoais, bem como de se
            opor ao processamento dos mesmos.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.10. Consentimento: Ao utilizar os serviços do RifaAi, os usuários
            concordam com a coleta, uso e compartilhamento de seus dados
            pessoais de acordo com esta Política de Privacidade.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.11. Alterações na Política de Privacidade: O RifaAi reserva-se o
            direito de modificar esta Política de Privacidade a qualquer
            momento. As alterações serão publicadas na plataforma e os usuários
            serão notificados sobre as mudanças.
          </div>
        </div>
        <div className="row text-muted">
          <div className="col">
            1.12. Contato: Para obter mais informações sobre esta Política de
            Privacidade ou exercer seus direitos de privacidade, os usuários
            podem entrar em contato com o RifaAi através dos canais de suporte
            fornecidos na plataforma.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Termos;
