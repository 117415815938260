import { Image, Accordion, Grid, Container, Title } from "@mantine/core";
import classes from "./FaqWithImage.module.css";
import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import FAQGif from "../../assets/FAQs.gif";
const placeholder =
  "It can’t help but hear a pin drop from over half a mile away, so it lives deep in the mountains where there aren’t many people or Pokémon.";

export function FaqWithImage() {
  return (
    <div>
      <NavBar />
      <div className={classes.wrapper}>
        <Container size="lg">
          <Grid id="faq-grid" gutter={50}>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Image src={FAQGif} alt="Frequently Asked Questions" />
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 6 }}>
              <Title order={2} ta="left" className={classes.title}>
                Perguntas Frequentes
              </Title>

              <Accordion
                chevronPosition="right"
                defaultValue="reset-password"
                variant="separated"
              >
                <Accordion.Item className={classes.item} value="reset-password">
                  <Accordion.Control>
                    Como posso receber os bilhetes após a compra?
                  </Accordion.Control>
                  <Accordion.Panel>
                    Após efetuar o pagamento, basta acessar a página de
                    Bilhetes, inserir o email utilizado na compra e verificar os
                    seus números.
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item
                  className={classes.item}
                  value="another-account"
                >
                  <Accordion.Control>
                    Como saberei se o meu bilhete é o premiado?
                  </Accordion.Control>
                  <Accordion.Panel>
                    Ao verificar seus bilhetes na aba 'Bilhetes', o bilhete
                    premiado estará destacado dos demais.
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="newsletter">
                  <Accordion.Control>
                    Como o prêmio será entregue?
                  </Accordion.Control>
                  <Accordion.Panel>
                    Após a finalização do sorteio, entraremos em contato através
                    dos dados fornecidos na compra.
                  </Accordion.Panel>
                </Accordion.Item>

                <Accordion.Item className={classes.item} value="credit-card">
                  <Accordion.Control>
                    Você armazena informações de cartão de crédito com
                    segurança?
                  </Accordion.Control>
                  <Accordion.Panel>
                    Não mantemos os dados do cartão salvos. A compra é realizada
                    através do MercadoPago, e somente eles têm acesso aos dados
                    sensíveis para preservar a sua segurança.
                  </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item className={classes.item} value="numeros-gerados">
                  <Accordion.Control>
                    Como são gerados os números do sorteio?
                  </Accordion.Control>
                  <Accordion.Panel>
                    Utilizamos um sistema seguro e avançado para garantir a
                    aleatoriedade dos números sorteados.
                  </Accordion.Panel>
                </Accordion.Item>
              </Accordion>
            </Grid.Col>
          </Grid>
        </Container>
      </div>
      <Footer />
    </div>
  );
}
