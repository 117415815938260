import { Carousel } from "@mantine/carousel";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import "@mantine/carousel/styles.css";
import {
  Badge,
  Button,
  Checkbox,
  Code,
  Group,
  Input,
  Modal,
  NumberInput,
  Pill,
  Text,
  TextInput,
  rem,
} from "@mantine/core";
import {
  IconAt,
  IconCircle,
  IconClover,
  IconStar,
  IconTicket,
} from "@tabler/icons-react";
import { useEffect, useRef, useState } from "react";
import { useDisclosure, useMediaQuery } from "@mantine/hooks";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function Rifa() {
  const params = useParams();
  const navigate = useNavigate();

  const [qtdRifas, setQtdRifas] = useState(5);
  const [opened, { open, close }] = useDisclosure(false);
  const [openedCompra, { open: openCompra, close: closeCompra }] =
    useDisclosure();
  const [rifa, setRifa] = useState([]);

  const isMobile = useMediaQuery("(max-width: 50em)");
  const iconEmail = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [emailConfirm, setEmailConfirm] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [termo, setTermo] = useState(false);
  const [loadingPagar, setLoadingPagar] = useState(false);
  const [linkPagamento, setLinkPagamento] = useState("");

  function getRifa() {
    axios({
      method: "get",
      url: `https://rifa-prod.onrender.com/api/Rifa/${params.id}`,
      // headers: {
      //   authorization: "Bearer " + token,
      // },
    })
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
        setRifa(res.data);
      })
      .catch((err) => {
        console.log("🚀 ~ Cadastrar ~ err:", err);
        Swal.fire(
          `Tivemos um problema ao buscar as rifas, por favor entre em contato com um administrador !`,
          "",
          "error"
        );
      });
  }

  function Pagar() {
    if (qtdRifas * rifa.precoBilhete.toFixed(2) < rifa.precoMinimo) {
      return Swal.fire(
        `O valor mínimo para adquirir é R$${rifa.precoMinimo} !`,
        "",
        "error"
      );
    }
    if (nome.length < 5) {
      return Swal.fire(`Informe seu nome !`, "", "error");
    }
    if (email.length < 5) {
      return Swal.fire(`Informe seu email !`, "", "error");
    }
    if (!email.includes("@")) {
      return Swal.fire(`Informe um email váilido !`, "", "error");
    }
    if (email !== emailConfirm) {
      return Swal.fire(`Os emails não conferem !`, "", "error");
    }
    if (telefone.length < 8) {
      return Swal.fire(`Informe um telefone válido !`, "", "error");
    }
    if (!/^[0-9]{11}$/.test(cpf)) {
      return Swal.fire(`Informe um cpf váilido !`, "", "error");
    }
    if (!termo) {
      return Swal.fire(
        `Aceite os termos para realizar a compra !`,
        "",
        "error"
      );
    }
    setLoadingPagar(true);
    axios({
      method: "post",
      url: `https://rifa-prod.onrender.com/api/Pagamento/CreatePreference`,
      // headers: {
      //   authorization: "Bearer " + token,
      // },
      data: {
        titulo: rifa.titulo,
        quantidade: qtdRifas,
        valor: rifa.precoBilhete,
        nome: nome,
        email: email,
        telefone: telefone,
        cpf: cpf,
        imagemUrl: rifa.urlImagem1,
        rifaId: params.id,
      },
    })
      .then((res) => {
        setLoadingPagar(false);
        console.log(res.data);
        setLinkPagamento(res.data.initPoint);
        window.open(res.data.initPoint, "_blank");
        openCompra(true)
      })
      .catch((err) => {
        setLoadingPagar(false);
        Swal.fire(
          `Tivemos um problema ao gerar o pagamento, por favor tente novamente ou entre em contato com um administrador !`,
          "",
          "error"
        );
        console.log(err);
      });
  }

  useEffect(() => {
    getRifa();
  }, []);

  return (
    <div>
      <NavBar />
      <div className="container">
        <div className="row mt-4">
          <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
            <div
              id="carouselExampleIndicators"
              class="carousel slide"
              data-bs-ride="true"
            >
              <div class="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="0"
                  class="active"
                  aria-current="true"
                  aria-label="Slide 1"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                ></button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleIndicators"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                ></button>
              </div>
              <div class="carousel-inner">
                <div class="carousel-item active">
                  <img
                    src={rifa.urlImagem1}
                    class="d-block w-100 rounded "
                    style={{ maxHeight: "30rem" }}
                    alt="..."
                  />
                </div>
                {rifa.urlImagem2 ? (
                  <div class="carousel-item">
                    <img
                      src={rifa.urlImagem2}
                      class="d-block w-100 rounded "
                      alt="..."
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                {rifa.urlImagem3 ? (
                  <div class="carousel-item">
                    <img
                      src={rifa.urlImagem3}
                      class="d-block w-100 rounded "
                      alt="..."
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="prev"
              >
                <span
                  class="carousel-control-prev-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide="next"
              >
                <span
                  class="carousel-control-next-icon"
                  aria-hidden="true"
                ></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          <div
            className="col border rounded-4 text-center"
            style={{ backgroundColor: "white" }}
          >
            <div className="row">
              <div className="col">
                <div className="mt-2">
                  <h3>{rifa.titulo}</h3>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="mt-2">
                  <Text size="sm" c="dimmed">
                    • POR APENAS{" "}
                    <Badge className="mx-2" color="green">
                      R${rifa.precoBilhete}
                    </Badge>
                  </Text>
                </div>
              </div>
            </div>
            <hr className="form-control" />
            <div className="row mt-2 p-2">{rifa.descricao}</div>
          </div>
        </div>
        <hr className="form-control" />
        <div className="row align-items-center">
          <div className="col-auto mr-2">
            <Button variant="default" size="md" radius="xl">
              <IconStar />
            </Button>
          </div>
          <div className="col">
            <h2 className="mb-0">Bilhetes premiados</h2>
          </div>
        </div>
        <div
          className="rounded-3 border-rounded "
          style={{ backgroundColor: "white" }}
        >
          {rifa.numero1 ? (
            <div>
              <div className="row mx-2 mt-2">
                <div className="col mt-2 mb-2">
                  <Button variant="light" size="xs" color="green">
                    {rifa.numero1}
                  </Button>
                  <Button variant="light" size="xs" color="green">
                    <IconClover
                      className="mx-1"
                      style={{ width: "1rem", marginTop: "2.5px" }}
                    />
                  </Button>
                </div>
              </div>
              <div className="row mx-2">
                <div className="col">
                  <Text size="sm" c="dimmed">
                    {rifa.descricaoNumero1}
                  </Text>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {rifa.numero2 ? (
            <div>
              <div className="row mx-2 mt-2">
                <div className="col mt-2 mb-2">
                  <Button variant="light" size="xs" color="green">
                    {rifa.numero2}
                  </Button>
                  <Button variant="light" size="xs" color="green">
                    <IconClover
                      className="mx-1"
                      style={{ width: "1rem", marginTop: "2.5px" }}
                    />
                  </Button>
                </div>
              </div>
              <div className="row mx-2">
                <div className="col">
                  <Text size="sm" c="dimmed">
                    {rifa.descricaoNumero2}
                  </Text>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {rifa.numero3 ? (
            <div>
              <div className="row mx-2 mt-2">
                <div className="col mt-2 mb-2">
                  <Button variant="light" size="xs" color="green">
                    {rifa.numero3}
                  </Button>
                  <Button variant="light" size="xs" color="green">
                    <IconClover
                      className="mx-1"
                      style={{ width: "1rem", marginTop: "2.5px" }}
                    />
                  </Button>
                </div>
              </div>
              <div className="row mx-2">
                <div className="col">
                  <Text size="sm" c="dimmed">
                    {rifa.descricaoNumero3}
                  </Text>
                </div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        <hr className="form-control" />
        <div className="row align-items-center">
          <div className="col-auto mr-2">
            <Button variant="default" size="md" radius="xl">
              <IconTicket />
            </Button>
          </div>
          <div className="col">
            <h2 className="mb-0">Bilhetes</h2>
          </div>
        </div>
        <div
          className="rounded-3 border-rounded text-center mt-2"
          style={{ backgroundColor: "white" }}
        >
          <div className="row text-center justify-content-center">
            <div className="col-6 mt-4 p-2">
              <h5>Selecione a quantidade de bilhetes</h5>
              <input
                type="number"
                name=""
                id=""
                min={5}
                className="form-control"
                value={qtdRifas}
                onChange={(e) =>
                  setQtdRifas(e.target.value.replace(/[^0-9]/g, ""))
                }
              />
            </div>
          </div>
          <div>
            <div className="row text-center justify-content-center">
              <div className="col-auto">
                <Button
                  variant="outline"
                  color="green"
                  size="md"
                  radius="lg"
                  onClick={(e) => setQtdRifas(parseInt(qtdRifas) + 2)}
                >
                  <IconClover size={20} />
                  Adicionar +2
                </Button>
              </div>
              <div className="col-auto">
                <Button
                  variant="outline"
                  color="green"
                  size="md"
                  radius="lg"
                  onClick={(e) => setQtdRifas(parseInt(qtdRifas) + 10)}
                >
                  <IconClover size={20} />
                  Adicionar +10
                </Button>
              </div>
              <div className="col-auto">
                <Button
                  variant="outline"
                  color="green"
                  size="md"
                  radius="lg"
                  onClick={(e) => setQtdRifas(parseInt(qtdRifas) + 25)}
                >
                  <IconClover size={20} />
                  Adicionar +25
                </Button>
              </div>
              <div className="col-auto">
                <Button
                  variant="outline"
                  color="green"
                  size="md"
                  radius="lg"
                  onClick={(e) => setQtdRifas(parseInt(qtdRifas) + 50)}
                >
                  <IconClover size={20} />
                  Adicionar +50
                </Button>
              </div>
            </div>
            <div className="row text-center justify-content-center mt-2 mb-3 p-3">
              <div className="col">
                <Button variant="filled" onClick={open}>
                  Participar (R${(qtdRifas * rifa.precoBilhete).toFixed(2)})
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Modal
        opened={opened}
        onClose={close}
        title="Finalize sua compra"
        fullScreen={isMobile}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <Text>Bilhetes: {qtdRifas}</Text>
        <Text c="green">
          Valor total: R${(qtdRifas * rifa.precoBilhete).toFixed(2)}
        </Text>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Nome" withAsterisk>
              <Input
                placeholder="Digite seu nome"
                autoComplete="name"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <di className="col">
            <Input.Wrapper label="Email" withAsterisk>
              <Input
                leftSection={iconEmail}
                placeholder="seuemail@email.com"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Input.Wrapper>
          </di>
        </div>
        <div className="row">
          <di className="col">
            <Input.Wrapper label="Comfirmação de email" withAsterisk>
              <Input
                leftSection={iconEmail}
                placeholder="seuemail@email.com"
                autoComplete="email"
                value={emailConfirm}
                onChange={(e) => setEmailConfirm(e.target.value)}
              />
            </Input.Wrapper>
          </di>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Telefone" withAsterisk>
              <Input
                placeholder="37999999999"
                autoComplete="tel"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value.replace(/\D/g, ""))}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="CPF" withAsterisk>
              <Input
                autoComplete="on"
                value={cpf}
                onChange={(e) =>
                  setCpf(e.target.value.replace(/\D/g, "").substring(0, 11))
                }
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-1">
            <Checkbox
              size="xs"
              value={termo}
              onChange={(event) => setTermo(event.currentTarget.checked)}
            />
          </div>
          <div className="col">
            <Text size="sm">
              Declaro que li e concordo com os{" "}
              <a href="/termos" style={{ color: "green" }}>
                Termos e Condições
              </a>
            </Text>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {loadingPagar ? (
              <Button size="xs" loading fullWidth onClick={Pagar}>
                Reservar bilhetes
              </Button>
            ) : (
              <Button size="xs" fullWidth onClick={Pagar}>
                Reservar bilhetes
              </Button>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        opened={openedCompra}
        onClose={closeCompra}
        title="Ordem de compra RifaAi "
        fullScreen={isMobile}
        transitionProps={{ transition: "fade", duration: 200 }}
      >
        <div className="row">
          <div className="col">
            Caro(a) {nome}, Seja muito bem vindo(a) à RifaAi
          </div>
        </div>
        <div className="row mt-2">
          <div className="col">
            Uma nova ordem de compra foi gerada para a rifa{" "}
            <span className="text-success">{rifa.titulo}</span> na quantidade de{" "}
            {qtdRifas} bilhetes
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            Se você não foi redirecionado automaticamente clique{" "}
            <a href={linkPagamento} target="_blank" rel="noopener noreferrer">
              aqui
            </a>{" "}
            para realizar o pagamento ou copie e cole o link abaixo
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Code block>{linkPagamento}</Code>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            Ao realizar o pagamento acesse a aba "Bilhetes" e insira o email de
            compra para consultar os seus bilhetes.
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            Se você tiver alguma dúvida sobre sua conta ou qualquer outra
            informação do site, por favor não deixe de entrar em contato
            rifaai.contact@gmail.com.
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Rifa;
