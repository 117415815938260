import { Badge, Button, Card, Group, Text } from "@mantine/core";
import CardRifas from "../../components/CardRifas";
import Footer from "../../components/Footer";
import NavBarAdmin from "../../components/navBarAdmin/NavBarAdmin";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { IconFile, IconPencil, IconPlus, IconTrash } from "@tabler/icons-react";
import Swal from "sweetalert2";

function Admin() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");

  const [rifas, setRifas] = useState([]);
  function BuscarRifas() {
    axios({
      method: "get",
      url: `https://rifa-prod.onrender.com/api/Rifa`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        setRifas(res.data);
        console.log("🚀 ~ .then ~ res.data:", res.data);
      })
      .catch((err) => {
        console.log("🚀 ~ Cadastrar ~ err:", err);
        Swal.fire(
          `Tivemos um problema ao buscar as rifas, por favor entre em contato com um administrador !`,
          "",
          "error"
        );
      });
  }

  function Delete(id) {
    console.log("🚀 ~ Delete ~ id:", id);
    if (
      // eslint-disable-next-line no-restricted-globals
      confirm(
        "Deseja excluir o cadastro selecionado, essa ação excluirá todos os números ?"
      ) === false
    ) {
      return;
    }
    axios({
      method: "delete",
      url: `https://rifa-prod.onrender.com/api/Rifa/${id}`,
      headers: {
        authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        Swal.fire("Rifa excluida !", "", "success");
        BuscarRifas();
      })
      .catch((err) => {
        Swal.fire("Ocorreu um erro ao excluir a rifa !", "", "error");
        console.log("🚀 ~ Cadastrar ~ err:", err);
      });
  }

  useEffect(() => {
    BuscarRifas();
  }, []);
  return (
    <div>
      <NavBarAdmin />
      <div className="container mt-4 col-xs-6 col-sm-6 col-md-6 col-lg-7 ">
        <Button
          leftSection={<IconPlus size={14} />}
          color="green"
          radius="md"
          onClick={(e) => navigate("/criar")}
        >
          Adicionar novo
        </Button>
        <div className="row mt-2">
          {rifas ? (
            rifas.map((item) => (
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                  <Group w={300} justify="space-between" mt="md" mb="xs">
                    <Text truncate="end" fw={500}>
                      {item.titulo}
                    </Text>
                  </Group>
                  {item.status == "Finalizado" ? (
                    <Badge color="red">Finalizado</Badge>
                  ) : (
                    <Badge color="green">Disponível</Badge>
                  )}
                  <Button
                    leftSection={<IconPencil />}
                    color="blue"
                    mt="md"
                    radius="md"
                    onClick={(e) => navigate(`/editar/${item.id}`)}
                  >
                    Editar
                  </Button>
                  <Button
                    leftSection={<IconTrash />}
                    color="red"
                    mt="md"
                    radius="md"
                    onClick={(e) => Delete(item.id)}
                  >
                    Excluir
                  </Button>
                  <Button
                    leftSection={<IconFile />}
                    color="green"
                    mt="md"
                    radius="md"
                    onClick={(e) => navigate(`/bilhetesrifa/${item.id}`)}
                  >
                    Ver Bilhetes
                  </Button>
                </Card>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Admin;
