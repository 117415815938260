import { Badge, Button, Input, Loader, Modal, rem } from "@mantine/core";
import NavBar from "../components/NavBar";
import { IconAt } from "@tabler/icons-react";
import Footer from "../components/Footer";
import { useState } from "react";
import axios from "axios";
import { useDisclosure } from "@mantine/hooks";
import Swal from "sweetalert2";

function Bilhetes() {
  const iconEmail = <IconAt style={{ width: rem(16), height: rem(16) }} />;

  const [email, setEmail] = useState("");
  const [compras, setCompras] = useState([]);
  const [opened, { open, close }] = useDisclosure(false);
  const [bilhetes, setBilhetes] = useState([]);
  const [loadBilhetes, setLoadBilhetes] = useState(false);
  const [loadVerBilhetes, setLoadVerBilhetes] = useState(false);

  function ConsultarCompras() {
    setLoadVerBilhetes(true);
    axios({
      method: "post",
      url: `https://rifa-prod.onrender.com/api/Pagamento/GetByEmail/${email}`,
      // headers: {
      //   authorization: "Bearer " + token,
      // },
    })
      .then((res) => {
        console.log(res.data);
        setCompras(res.data.reverse());
        setLoadVerBilhetes(false);
      })
      .catch((err) => {
        console.log(err);
        setLoadVerBilhetes(false);
        Swal.fire(`Tivemos um problema ao consultar as compras, por favor entre em contato com um administrador !`, "", "error");

      });
  }

  function VerBilhetes(id) {
    setLoadBilhetes(true);
    open(true);
    axios({
      method: "get",
      url: `https://rifa-prod.onrender.com/api/Bilhete/${id}`,
      // headers: {
      //   authorization: "Bearer " + token,
      // },
    })
      .then((res) => {
        setLoadBilhetes(false);
        console.log(res.data);
        setBilhetes(res.data);
      })
      .catch((err) => {
        setLoadBilhetes(false);
        console.log(err);
        Swal.fire(`Tivemos um problema ao ver os bilhetes, por favor entre em contato com um administrador !`, "", "error");

      });
  }

  return (
    <div>
      <NavBar />
      <div
        className="container border rounded-4 mt-4 "
        style={{ backgroundColor: "white" }}
      >
        <div className="mt-2 p-2">
          <h3>Resgatar Bilhetes</h3>
        </div>
        <div className="row mb-3 mt-2 p-2">
          <di className="col">
            <Input.Wrapper label="Email" withAsterisk>
              <Input
                leftSection={iconEmail}
                placeholder="seuemail@email.com"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Input.Wrapper>
          </di>
          <div className="col mt-4">
            <Button onClick={ConsultarCompras}>Ver meus bilhetes</Button>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="d-flex justify-content-center mt-4">
              {loadVerBilhetes ? (
                <Loader color="green" />
              ) : (
                <div>
                  {compras ? (
                    compras.map((item) => (
                      <div>
                        <div className="col mt-2 d-flex justify-content-center">
                          <div
                            className="card mb-3"
                            style={{ maxWidth: "540px" }}
                          >
                            <div className="row g-0">
                              <div className="col-md-4">
                                <img
                                  src={item.imageUrl}
                                  className="img-fluid rounded-start"
                                  alt="..."
                                />
                              </div>
                              <div className="col-md-8">
                                <div className="card-body">
                                  <h5 className="card-title">{item.title}</h5>
                                  <p className="card-text">
                                    {item.status === "approved" ? (
                                      <Badge color="green">Aprovado</Badge>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.status === "rejected" ? (
                                      <Badge color="red">Recusado</Badge>
                                    ) : (
                                      <div></div>
                                    )}
                                    {item.status === "in_process" ? (
                                      <Badge color="yellow">Processando</Badge>
                                    ) : (
                                      <div></div>
                                    )}
                                  </p>
                                  <div className="card-text">
                                    {item.status === "approved" ? (
                                      <Button
                                        color="blue"
                                        radius="md"
                                        onClick={(e) => VerBilhetes(item.id)}
                                      >
                                        Ver meus {item.quantity} Bilhetes
                                      </Button>
                                    ) : (
                                      <Button color="blue" radius="md" disabled>
                                        Ver meus {item.quantity} Bilhetes
                                      </Button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div></div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <Modal opened={opened} onClose={close} title="Bilhetes">
          {loadBilhetes ? (
            <div className="d-flex justify-content-center">
              <Loader color="blue" />
            </div>
          ) : (
            <div>
              {bilhetes ? (
                bilhetes.map((item) =>
                  item.premiado ? (
                    <Badge variant="light" color="green" radius="lg">
                      {item.numero}
                    </Badge>
                  ) : (
                    <Badge variant="light" color="blue" radius="lg">
                      {item.numero}
                    </Badge>
                  )
                )
              ) : (
                <div></div>
              )}
            </div>
          )}
        </Modal>
      </div>
      <Footer />
    </div>
  );
}

export default Bilhetes;
