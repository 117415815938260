import { IconHome } from "@tabler/icons-react";
import RifaAiLogo from "../assets/RifaAi.png";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <div>
      <nav class="navbar navbar-expand-lg  custom-navbar">
        <div class="container-fluid">
          <a class="navbar-brand mx-5" href="/">
            <img src={RifaAiLogo} style={{ width: "3.5rem" }} />
          </a>
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
        </div>
        <div
          class="collapse navbar-collapse justify-content-center"
          id="navbarScroll"
        >
          <ul class="navbar-nav">
            <li className="nav-item">
              <Link to="/" className="nav-link d-flex">
                <span className="bi bi-ticket-perforated mx-1"></span> Campanhas
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/bilhetes" className="nav-link d-flex ">
                <span className="bi bi-ticket-detailed mx-1"></span> Bilhetes
              </Link>
            </li>
            <li className="nav-item">
              <Link to="/faq" className="nav-link d-flex">
                <span className="bi bi-question-circle mx-1"></span> FAQ
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default NavBar;
