import { Card, Image, Text, Badge, Button, Group } from "@mantine/core";
import { useNavigate } from "react-router-dom";

function CardRifas(props) {
  const navigate = useNavigate();

  return (
    <Card shadow="sm" padding="lg" radius="md" withBorder>
      <Card.Section>
        <Image
          className="img-fluid"
          src={props.urlImagem1}
          h={200}
          alt="Norway"
        />
      </Card.Section>

      <Group justify="space-between" mt="md" mb="xs">
        <Text fw={500}>{props.titulo}</Text>
      </Group>
      {props.status == "Finalizado" ? (
        <Badge color="red">{props.status}</Badge>
      ) : (
        <Badge color="green">{props.status}</Badge>
      )}

      <Text size="sm" c="dimmed">
        {props.subTitulo}
      </Text>

      <div className="mt-2">
        <Text fz="xl" fw={700} style={{ lineHeight: 1 }}>
          R${props.precoBilhete}
        </Text>
        <Text fz="sm" c="dimmed" fw={500} style={{ lineHeight: 1 }} mt={3}>
          por bilhete
        </Text>
      </div>

      {props.status == "Finalizado" ? (
        <Button
          color="blue"
          fullWidth
          mt="md"
          radius="md"
          disabled
          onClick={(e) => navigate(`/rifa/${props.id}`)}
        >
          Comprar
        </Button>
      ) : (
        <Button
          color="blue"
          fullWidth
          mt="md"
          radius="md"
          onClick={(e) => navigate(`/rifa/${props.id}`)}
        >
          Comprar
        </Button>
      )}
    </Card>
  );
}

export default CardRifas;
