import { Button, Loader } from "@mantine/core";
import NavBar from "../components/NavBar";
import "./inicio.css";
import { IconTicket } from "@tabler/icons-react";
import CardRifas from "../components/CardRifas";
import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
function Inicio() {
  const [rifas, setRifas] = useState([]);
  const [loadRifas, setLoadRifas] = useState(false);

  function BuscarRifas() {
    setLoadRifas(true)
    axios({
      method: "get",
      url: `https://rifa-prod.onrender.com/api/Rifa`,
      // headers: {
      //   authorization: "Bearer " + token,
      // },
    })
      .then((res) => {
        console.log("🚀 ~ .then ~ res:", res);
       setLoadRifas(false)
        setRifas(res.data);
      })
      .catch((err) => {
        setLoadRifas(false)
        console.log("🚀 ~ Buscar ~ err:", err);
      });
  }

  useEffect(() => {
    BuscarRifas();
  }, []);

  return (
    <div>
      <NavBar />
      <div className="container mt-4 col-xs-6 col-sm-6 col-md-6 col-lg-7 ">
        <div className="row align-items-center">
          <div className="col-auto mr-2">
            <Button variant="default" size="md" radius="xl">
              <IconTicket />
            </Button>
          </div>
          <div className="col">
            <h2 className="mb-0">Campanhas</h2>
          </div>
        </div>
        <div className="row mt-5">
          {loadRifas ? <div><Loader color="green" size="sm" /></div> : <div></div> }
          {rifas ? (
            rifas.map((item) => (
              <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 mt-2">
                <CardRifas
                  urlImagem1={item.urlImagem1}
                  titulo={item.titulo}
                  status={item.status}
                  subTitulo={item.subTitulo}
                  precoBilhete={item.precoBilhete}
                  id={item.id}
                />
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Inicio;
