import {
  Button,
  FileInput,
  Input,
  NativeSelect,
  NumberInput,
  Textarea,
} from "@mantine/core";
import Footer from "../../components/Footer";
import NavBarAdmin from "../../components/navBarAdmin/NavBarAdmin";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { IconArrowLeft } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

function CriarRifa() {
  const navigate = useNavigate();
  const token = window.localStorage.getItem("x-access-token");

  const [titulo, setTitulo] = useState("");
  const [subtitulo, setSubTitulo] = useState("");
  const [descricao, setDescricao] = useState("");
  const [precoBilhete, setPrecoBilhete] = useState("");
  const [precoMinimoCompra, setPrecoMinimoCompra] = useState("");
  const [status, setStatus] = useState("Disponível");
  const [numero1, setNumero1] = useState("");
  const [numero2, setNumero2] = useState("");
  const [numero3, setNumero3] = useState("");
  const [descricao1, setDescricao1] = useState("");
  const [descricao2, setDescricao2] = useState("");
  const [descricao3, setDescricao3] = useState("");
  const [emailPremiado1, setEmailPremiado1] = useState("");
  const [emailPremiado2, setEmailPremiado2] = useState("");
  const [emailPremiado3, setEmailPremiado3] = useState("");
  const [imagem1, setImagem1] = useState(null);
  const [imagem2, setImagem2] = useState(null);
  const [imagem3, setImagem3] = useState(null);
  const [loading, setLoading] = useState(false);

  function Cadastrar() {
    const formData = new FormData();

    if (!titulo) {
      Swal.fire("Informe um título !", "", "error");
      return;
    }
    if (!subtitulo) {
      Swal.fire("Informe um sub título !", "", "error");
      return;
    }
    if (!descricao) {
      Swal.fire("Informe uma descrição !", "", "error");
      return;
    }
    if (descricao.length < 10) {
      Swal.fire("A descrição deve ter no mínimo 10 caracteres !", "", "error");
      return;
    }
    if (!precoBilhete) {
      Swal.fire("Informe o preço por bilhete !", "", "error");
      return;
    }
    if (!precoBilhete) {
      Swal.fire("Informe o preço mínimo de compra !", "", "error");
      return;
    }
    if (!numero1 && !numero2 && !numero3) {
      Swal.fire("Informe no mínimo um número para a rifa !", "", "error");
      return;
    }
    if (numero1 && numero1.length < 6) {
      Swal.fire("Insira os 6 digitos do número 1 !", "", "error");
      return;
    }

    if (numero2 && numero2.length < 6) {
      Swal.fire("Insira os 5 digitos do número 2 !", "", "error");
      return;
    }
    if (numero3 && numero3.length < 6) {
      Swal.fire("Insira os 6 digitos do número 3 !", "", "error");
      return;
    }
    if (!imagem1) {
      Swal.fire("Insira um banner !", "", "error");
      return;
    }

    formData.append("Titulo", titulo);
    formData.append("SubTitulo", subtitulo);
    formData.append("Descricao", descricao);
    formData.append("PrecoBilhete", precoBilhete);
    formData.append("PrecoMinimo", precoMinimoCompra);
    formData.append("Status", status);
    formData.append("Numero1", numero1);
    formData.append("Numero2", numero2);
    formData.append("Numero3", numero3);
    formData.append("DescricaoNumero1", descricao1);
    formData.append("DescricaoNumero2", descricao2);
    formData.append("DescricaoNumero3", descricao3);
    formData.append("EmailPremiado1", emailPremiado1);
    formData.append("EmailPremiado2", emailPremiado2);
    formData.append("EmailPremiado3", emailPremiado3);
    formData.append("Imagem1", imagem1);
    formData.append("Imagem2", imagem2);
    formData.append("Imagem3", imagem3);

    setLoading(true);
    axios({
      method: "post",
      url: `https://rifa-prod.onrender.com/api/Rifa`,
      data: formData,
       headers: {
         authorization: "Bearer " + token,
       },
    })
      .then((res) => {
        setLoading(false);
        Swal.fire("Rifa cadastrada !", "", "success");
        ClearData();
      })
      .catch((err) => {
        setLoading(false);
        console.log("🚀 ~ Cadastrar ~ err:", err);
        Swal.fire("Erro ao cadastrar rifa", "", "error");
      });
  }

  function ClearData() {
    setTitulo("");
    setSubTitulo("");
    setDescricao("");
    setPrecoBilhete("");
    setPrecoMinimoCompra("");
    setStatus("Disponível");
    setNumero1("");
    setNumero2("");
    setNumero3("");
    setDescricao1("");
    setDescricao2("");
    setDescricao3("");
    setEmailPremiado1("");
    setEmailPremiado2("");
    setEmailPremiado3("");
    setImagem1(null);
    setImagem2(null);
    setImagem3(null);
  }
  return (
    <div>
      <NavBarAdmin />
      <div
        className="container p-3 mt-4 col-xs-6 col-sm-6 col-md-6 col-lg-7 border rounded-4"
        style={{ backgroundColor: "white" }}
      >
           <Button leftSection={<IconArrowLeft size={14} />} color="green" radius="md" onClick={(e) => navigate("/admin")}>
          Voltar
        </Button>
        <div className="row mt-2">
          <div className="col">
            <Input.Wrapper label="Título da Rifa" withAsterisk>
              <Input
                placeholder="Digite o titulo da rifa"
                onChange={(e) => setTitulo(e.target.value)}
                value={titulo}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Sub Título da Rifa" withAsterisk>
              <Input
                placeholder="Digite o sub titulo da rifa"
                onChange={(e) => setSubTitulo(e.target.value)}
                value={subtitulo}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Descrição" withAsterisk>
              <textarea
                className="form-control"
                id=""
                cols="30"
                rows="5"
                onChange={(e) => setDescricao(e.target.value)}
                value={descricao}
              ></textarea>
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Preço Por Bilhete" withAsterisk>
              <Input
                placeholder="Digite o preço por rifa"
                onChange={(e) =>
                  setPrecoBilhete(e.target.value.replace(/[^0-9.]/g, ""))
                }
                value={precoBilhete}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Preço mínimo de compra" withAsterisk>
              <Input
                placeholder="Digite o preço mínimo para comprar uma rifa"
                onChange={(e) =>
                  setPrecoMinimoCompra(e.target.value.replace(/[^0-9.]/g, ""))
                }
                value={precoMinimoCompra}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Status" withAsterisk>
              <NativeSelect
                data={["Disponível", "Finalizado"]}
                onChange={(e) => setStatus(e.target.value)}
                value={status}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Número 1 (000000 à 999999)" withAsterisk>
              <Input
                placeholder="Digite o número premiado"
                onChange={(e) =>
                  setNumero1(e.target.value.slice(0, 6).replace(/[^0-9,]/g, ""))
                }
                value={numero1}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Descrição do número" withAsterisk>
              <Input
                placeholder="Informe a descrição do número ex: Pix de R$200,00"
                onChange={(e) => setDescricao1(e.target.value)}
                value={descricao1}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Número 2 (000000 à 999999)" withAsterisk>
              <Input
                placeholder="Digite o número premiado"
                onChange={(e) =>
                  setNumero2(e.target.value.slice(0, 6).replace(/[^0-9,]/g, ""))
                }
                value={numero2}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Descrição do número" withAsterisk>
              <Input
                placeholder="Informe a descrição do número ex: Pix de R$200,00"
                onChange={(e) => setDescricao2(e.target.value)}
                value={descricao2}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper label="Número 3 (000000 à 999999)" withAsterisk>
              <Input
                placeholder="Digite o número premiado"
                onChange={(e) =>
                  setNumero3(e.target.value.slice(0, 6).replace(/[^0-9,]/g, ""))
                }
                value={numero3}
              />
            </Input.Wrapper>
          </div>
          <div className="col">
            <Input.Wrapper label="Descrição do número" withAsterisk>
              <Input
                placeholder="Informe a descrição do número ex: Pix de R$200,00"
                onChange={(e) => setDescricao3(e.target.value)}
                value={descricao3}
              />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper
              label="Informe o email que irá receber o número premiado 1"
              onChange={(e) => setEmailPremiado1(e.target.value)}
              value={emailPremiado1}
              withAsterisk
            >
              <Input placeholder="email@email.com" />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper
              label="Informe o email que irá receber o número premiado 2"
              onChange={(e) => setEmailPremiado2(e.target.value)}
              value={emailPremiado2}
              withAsterisk
            >
              <Input placeholder="email@email.com" />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Input.Wrapper
              label="Informe o email que irá receber o número premiado 1"
              onChange={(e) => setEmailPremiado3(e.target.value)}
              value={emailPremiado3}
              withAsterisk
            >
              <Input placeholder="email@email.com" />
            </Input.Wrapper>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <FileInput
              label="Imagem 1 (Banner)"
              placeholder="Selecione a imagem"
              value={imagem1}
              onChange={setImagem1}
            />
          </div>
          <div className="col">
            <FileInput
              label="Imagem 2 "
              placeholder="Selecione a imagem"
              value={imagem2}
              onChange={setImagem2}
            />
          </div>
          <div className="col">
            <FileInput
              label="Imagem 3 "
              placeholder="Selecione a imagem"
              value={imagem3}
              onChange={setImagem3}
            />
          </div>
        </div>
        <div className="row p-3">
          {loading ? (
            <Button fullWidth loading onClick={Cadastrar}>
              Cadastrar
            </Button>
          ) : (
            <Button fullWidth  onClick={Cadastrar}>
              Cadastrar
            </Button>
          )}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default CriarRifa;
